import Box from "@mui/material/Box";
import { useCallback, useEffect, useReducer, useRef } from "react";
import {
  Alert,
  Button,
  Checkbox,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useUploaderContext } from "../context/Context";
import {
  getDocumentCount,
  getDataConnectorFilters,
} from "api/endpoints/UploadApi";
import {
  SET_DATA_IMPORT_CONFIG,
  getInitialImportState,
  importDataReducer,
} from "../utils/constants";
import {
  CONNECTORS,
  CONNECTOR_FORM_TYPES,
  getRequestFilters,
} from "../utils/dataConnectors";
import { offsetDate, checkIfInFuture, formatNumber } from "utils/format";
import DateTimePicker from "components/UI/DateTimePicker.tsx";
import Preloader from "components/UI/Preloader";
import { useAppContext } from "context/Context";
import useEventTracker from "api/hooks/useEventTracker";
import AutoUpdates from "../Projects/AutoUpdates";
import {
  getOptions,
  isDateOutsideFreshnessWindow,
  validateScheduleConfig,
} from "../utils/utils";
import FormItem from "./FormItem";
import useApi from "api/hooks/useApi";
import { getCancelToken } from "api/endpoints/utils";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import TimezoneInfo from "components/UI/TimezoneInfo";

const useStyles = ({ theme, ...props }) => ({
  root: {
    maxWidth: "500px",
    margin: "0 auto",
    paddingBottom: props.fromDrawer ? "86px" : 0,
  },
  label: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "15px",
    color: "#AFAFAF",
    marginBottom: 1,
  },
  formRow: {
    padding: "15px 0px",
  },
  datePickerRow: {
    padding: "15px 0px",

    "& .DateInput": {
      width: "calc(50% - 20px)",
      borderRadius: "2px",
    },
    "& .DateRangePickerInput_arrow": {
      width: "40px",
      textAlign: "center",
    },
    "& .DateRangePicker": {
      width: "100%",
      border: theme.palette.border.widget,
      borderRadius: "2px",
    },
    "& .DateRangePickerInput": {
      borderRadius: "2px",
      width: "100%",
      background: theme.palette.background.$1,
    },
    "& .DateInput_input": {
      borderRadius: "2px",
      background: theme.palette.background.$1,
    },
    "& .time_input": {
      width: "140px",
    },
    "& .time_input .MuiInputBase-root": {
      borderRadius: "2px !important",
      background: theme.palette.background.$1 + " !important",
      border: theme.palette.border.widget,
    },
  },
  checkboxLabel: {
    color: "#AFAFAF",
    fontSize: "14px",
    lineHeight: "18px",
  },
  docCountSection: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  docCountText: {
    color: "#fff",
    fontWeight: "normal",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
  },
  autoUpdatesRow: {
    "& .time_input": {
      width: "140px",
    },
    "& .time_input .MuiInputBase-root": {
      borderRadius: "2px",
      background: theme.palette.background.$1 + " !important",
      border: theme.palette.border.widget + " !important",
    },
  },
});

const requiredText = "This field is required";

const docLimitErrorText =
  "The size for this import is beyond the allowed size for this data connector. please contact support";

const getCount = async (state, timezoneOffset, config) => {
  const connectorConfig = state.connectorConfig;
  const getPayload = () => {
    return {
      start_date: offsetDate(state.startDate, timezoneOffset),
      end_date: offsetDate(state.endDate, timezoneOffset),
      project_id: state.dataProviderProjectId,
      topic_id: state.dataProviderTopicId,
      query: state.searchQuery,
      platforms: state.selectedPlatform,
      mockConnectors: state.mockConnectors,
      // While we have shouldLoadFilters set to true for a few connectors, none actually use the filters object outside of easl
      // Further, in some other places that this is set (incremental jobs, both manual and auto update)
      // the properties are explicitly mapped (not from the connector config)
      filters: getRequestFilters(state.connectorConfig, state, timezoneOffset),
      secret_key: state.dataConnectorSecretKey,
    };
  };

  const payload = getPayload();
  const response = await getDocumentCount(
    connectorConfig?.key,
    payload,
    config
  );
  if (response?.error_message) {
    console.error(response.error_message, response?.details);
    throw new Error(response.error_message);
  }
  return response?.total_count || 0;
};

const isValid = (datum) => {
  if (Array.isArray(datum)) {
    return !!datum.length;
  }
  return !!datum;
};

export default function ImportData({
  fromDrawer,
  connectorConfig,
  onContinue,
  onCancel,
  hideAutoUpdate,
  hideDateRange,
  loading,
  configuringAutoUpdates,
}) {
  const theme = useTheme();
  const styles = useStyles({ theme, fromDrawer });
  const tracker = useEventTracker();
  const flags = useFlags();

  const {
    state: { dataConnector, dataImportConfig },
    dispatch,
  } = useUploaderContext();

  const {
    state: { timezoneOffset },
  } = useAppContext();

  const showMockConnectorsToggle = flags.easlSyntheticData;

  const [state, setState] = useReducer(
    importDataReducer,
    getInitialImportState({
      dataConnector,
      dataImportConfig,
      connectorConfig,
      // If shown, set its default value to true, otherwise false
      mockConnectors: showMockConnectorsToggle,
    })
  );

  const cancelToken = useRef();

  const getDocCountConfig = () => {
    if (cancelToken.current) {
      cancelToken.current.cancel();
    }
    cancelToken.current = getCancelToken();
    return { cancelToken: cancelToken.current.token, clearCacheEntry: true };
  };

  const { data: dataConnectorFilters, isLoading: filtersLoading } = useApi({
    apiKey: connectorConfig?.id,
    apiFn: () =>
      getDataConnectorFilters(connectorConfig?.key, {
        secret_key: state.dataConnectorSecretKey,
      }),
    enabled: !!connectorConfig?.shouldLoadFilters,
  });

  const olderEndDate =
    state.endDate && isDateOutsideFreshnessWindow(state.endDate);

  const disableAutoUpdates =
    !flags.autoUpdates || // if autoUpdates is off, it will disabled autoUpdates
    !connectorConfig.supportsAutoUpdate ||
    hideAutoUpdate ||
    olderEndDate;

  const showAlert =
    flags.autoUpdates && // if autoUpdates is on, it will show an alert
    connectorConfig.supportsAutoUpdate &&
    olderEndDate &&
    disableAutoUpdates;

  const keysToCheck = (connectorConfig?.fields || []).flatMap((d) => [
    d.field,
    ...(d.fields || []).map((x) => x.field),
  ]);

  const searchQueryField = connectorConfig?.fields.find(
    (d) => d.field === "searchQuery"
  );

  const maxCharsReached =
    searchQueryField && state.searchQuery?.length > searchQueryField.maxChars;

  const loadDocCount = useCallback(async () => {
    const dateRangeSelected = state.startDate && state.endDate;
    const dateConnectorKeyExists = !!state.dataConnectorSecretKey;
    const allFormSelected = keysToCheck.every((key) => isValid(state[key]));

    if (!dateRangeSelected || !dateConnectorKeyExists || !allFormSelected) {
      const errors = keysToCheck
        .filter((key) => !isValid(state[key]))
        .reduce((acc, key) => {
          acc[key] = true;
          return acc;
        }, {});

      // Reset the doc count to prevent user from proceeding.
      setState({
        docCount: 0,
        docCountLoading: false,
        generalError: null,
        error: errors,
      });

      return;
    }

    const config = getDocCountConfig();

    try {
      const pre_state = {
        docCountLoading: true,
        countNeedsUpdate: false,
        noDocsError: null,
        generalError: null,
      };

      if (searchQueryField) {
        pre_state.error = {};
      }

      setState(pre_state);

      const docCount = await getCount(state, timezoneOffset, config);

      const post_state = {
        docCountLoading: false,
        countNeedsUpdate: false,
        docCount,
        noDocsError: docCount ? null : connectorConfig.noDocsError,
      };

      setState(post_state);
    } catch (err) {
      // if request is canceled, no need to update state
      if (err.__CANCEL__) return;

      console.error(err);
      const error = err.response?.data?.error ?? err;
      const errorMessage = error?.toString();

      const post_state = {
        docCount: 0,
        docCountLoading: false,
        countNeedsUpdate: false,
      };

      const userErrorMessage = `Failed to retrieve document count. Please try again or reach out to your data provider if the problem persists. Details: ${errorMessage}`;
      post_state.generalError = userErrorMessage;

      if (searchQueryField) {
        post_state.error = { searchQuery: error };

        tracker.track(
          connectorConfig?.key + " error",
          "error",
          error,
          "Data Connectors / " + connectorConfig?.key
        );
      }

      setState(post_state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    connectorConfig,
    state.dataConnectorId,
    state.dataConnectorSecretKey,
    state.startDate,
    state.endDate,
    timezoneOffset,
    state.dataProviderProjectId,
    state.dataProviderTopicId,
    state.searchQuery,
    state.selectedPlatform,
  ]);

  useEffect(() => {
    tracker.track(
      "Step 2 - Import data",
      "select",
      {
        "Data source": connectorConfig?.displayName,
      },
      "Data Connectors / " + connectorConfig?.displayName
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectDateRange = (start, end) => {
    setState({
      startDate: start,
      endDate: end,
      countNeedsUpdate: true,
    });

    tracker.track(
      `Selected date range`,
      "select",
      {
        "Start date": offsetDate(start, timezoneOffset),
        "End date": offsetDate(end, timezoneOffset),
        "Data source": connectorConfig?.id,
      },
      `Data Connectors / ${connectorConfig?.displayName} Flow / Date range`
    );
  };

  const handleTosCheck = (e) => {
    setState({ tosChecked: e.target.checked });
    tracker.track(
      "Checked permissions checkbox",
      "checked",
      e.target.checked,
      `Data Connectors / ${connectorConfig?.displayName} Flow / Permissions checkbox`
    );
  };

  const handleAutoUpdatesCheck = (e) => {
    setState({ autoUpdatesOn: e.target.checked });
    tracker.track(
      "Checked enable auto updates",
      "checked",
      e.target.checked ? "on" : "off",
      `Data Connectors / ${connectorConfig?.displayName} Flow / Auto updates checkbox`
    );
  };

  const handleAutoUpdatesChange = (scheduleConfig) => {
    setState({ scheduleConfig });
    tracker.track(
      "Changed auto update cadence",
      "change",
      scheduleConfig?.frequency,
      `Data Connectors / ${connectorConfig?.displayName} Flow / Auto updates`
    );
  };

  const trackFormChange = (value, conf) => {
    const eventValue = {
      "Data source": connectorConfig?.id,
      [conf.actionName]: value,
    };

    tracker.track(
      `Selected ${conf.actionName}`,
      "select",
      eventValue,
      `Data Connectors / ${connectorConfig?.id} Flow / ${conf.field}`
    );
  };

  const handleFormChange = (value, conf, arr) => {
    // If document count is hidden, no need to set countNeedsUpdate to true
    const showingDocCount = !hideDateRange;
    const statesToUpdate = {
      [conf.field]: value,
      countNeedsUpdate: showingDocCount,
    };

    if (conf.type === CONNECTOR_FORM_TYPES.select) {
      const option = arr.find((x) => x.value === value);
      if (option) {
        statesToUpdate[conf.field + "_obj"] = option;
      }

      // Reset the value of the child fields (if any) when the selected value of the parent field has changed.
      const hasAnyChildFields = conf.fields?.length ?? 0;
      if (hasAnyChildFields) {
        const currentFieldValue = state[conf.field];
        if (currentFieldValue !== value) {
          conf.fields?.forEach((childField) => {
            const currentChildFieldHasValue = !!state[childField.field];
            if (currentChildFieldHasValue) {
              statesToUpdate[childField.field] = null;
              statesToUpdate[childField.field + "_obj"] = null;
            }
          });
        }
      }

      trackFormChange(value, conf);
    }

    setState(statesToUpdate);
  };

  const handleContinue = () => {
    setState({ generalError: null });

    const topicName =
      state["dataProviderTopicId_obj"]?.label || state.dataProviderTopicName;

    const payload = {
      name: topicName,
      searchQuery: state.searchQuery,
      dataProviderName: connectorConfig?.id,
      dataConnector: state.dataConnectorId,
      dataConnectorSecretKey: state.dataConnectorSecretKey,
      docCount: state.docCount,
      estimatedDocumentCount: state.docCount,
      // TODO: populate dynamically based on config fields
      dataProviderProjectId: state.dataProviderProjectId,
      dataProviderProjectId_obj: state.dataProviderProjectId_obj,
      dataProviderTopicId: state.dataProviderTopicId,
      dataProviderTopicName: topicName,
      selectedPlatform: state.selectedPlatform,
      mockConnectors: state.mockConnectors,
    };

    if (!fromDrawer) {
      payload.tosChecked = state.tosChecked;
      keysToCheck.push("tosChecked");
    }

    if (!hideDateRange) {
      payload.startDate = state.startDate;
      payload.endDate = state.endDate;
      keysToCheck.push("startDate", "endDate");
    }

    const errorObj = {};

    if (!disableAutoUpdates) {
      payload.scheduleConfig = state.scheduleConfig;
      payload.scheduled = state.autoUpdatesOn;

      const error = validateScheduleConfig(state.scheduleConfig);

      if (error) {
        errorObj.scheduleConfig = error;
      }
    }

    keysToCheck.forEach((key) => {
      if (!isValid(payload[key])) {
        errorObj[key] = true;
      }
    });

    if (maxCharsReached) {
      errorObj.searchQuery = "Character limit " + searchQueryField.maxChars;
    }

    if (Object.keys(errorObj).length) {
      return setState({ error: errorObj });
    }

    setState({ error: {} });

    const utcEndDate = offsetDate(state.endDate, timezoneOffset);

    if (checkIfInFuture(utcEndDate)) {
      return setState({
        generalError: "End date should not be in the future.",
      });
    }

    if (
      (state.docCount > 0 && state.docCount <= state.maxDocCount) ||
      configuringAutoUpdates
    ) {
      dispatch({
        type: SET_DATA_IMPORT_CONFIG,
        payload: payload,
      });

      onContinue(payload);
    } else if (state.docCount > state.maxDocCount) {
      setState({ generalError: docLimitErrorText });
    }
  };

  const handleDocsCalculateClick = () => {
    loadDocCount();

    tracker.track(
      "Clicked calculate documents",
      "click",
      "",
      "Data Connectors / " + connectorConfig?.key
    );
  };

  const footerStyles = fromDrawer
    ? {
        position: "absolute",
        bottom: "0px",
        right: "0px",
        width: "100%",
        display: "flex",
        padding: "24px",
        borderTop: "1px solid #302E4F",
        justifyContent: "space-between",
        background: "#272438",
      }
    : {
        mb: 2,
      };

  const renderDynamicFields = () => {
    return connectorConfig.fields.map((conf, i) => {
      const getParentSelectedItem = () => {
        const parentSelectedValue = state[conf.field]?.toString();
        if (!parentSelectedValue) return undefined;

        const parentSelectedItemKey = `${conf.field}_obj`;
        const parentSelectedItem = state[parentSelectedItemKey];
        if (parentSelectedItem) return parentSelectedItem;

        const sourcePropName = conf?.["source"]?.["propName"];
        if (!sourcePropName) return undefined;

        const parentOriginalItem = dataConnectorFilters?.[sourcePropName]?.find(
          (x) => x.id.toString() === parentSelectedValue
        );
        return parentOriginalItem;
      };

      const parentSelectedItem = getParentSelectedItem();
      return (
        <Box key={i}>
          {/* Main fields */}
          <FormItem
            {...conf}
            loading={conf.source.type === "easl-filters" && filtersLoading}
            options={getOptions(dataConnectorFilters, conf.source)}
            state={state}
            onChange={(value, arr) => handleFormChange(value, conf, arr)}
            onBlur={(e) => {
              if (conf.type === CONNECTOR_FORM_TYPES.boolInput) {
                trackFormChange(e.target.value, conf);
              }
            }}
          />
          {/* Dependent fields */}
          {parentSelectedItem &&
            conf.fields?.map((child, j) => {
              const options = getOptions(parentSelectedItem, child.source);
              const optionsLen = options?.length ?? 0;
              const isStatic = ["static", "bb-filters"].includes(
                child.source.type
              );

              const showChild = isStatic || optionsLen > 0;
              return showChild ? (
                <FormItem
                  key={j}
                  {...child}
                  state={state}
                  options={options}
                  onChange={(value, arr) => handleFormChange(value, child, arr)}
                />
              ) : (
                <Alert severity="error" key={j}>
                  {`No ${child.displayName.toLowerCase()} is associated with the selected ${conf.displayName.toLowerCase()}.`}
                </Alert>
              );
            })}
        </Box>
      );
    });
  };

  const renderFilterControls = () => {
    const showChatAlert =
      connectorConfig?.key === CONNECTORS.webz &&
      state?.selectedPlatform?.includes("chat");

    return (
      <>
        {renderDynamicFields()}

        {!hideDateRange && (
          <Box sx={styles.datePickerRow}>
            <Typography sx={styles.label}>Select date range</Typography>

            <Box sx={{ display: "inline-block", mb: 1.5 }}>
              <TimezoneInfo />
            </Box>

            <DateTimePicker
              data-testid="date-picker"
              error={state.error.startDateTime || state.error.endDateTime}
              helperText={requiredText}
              dateFrom={state.startDate}
              dateTo={state.endDate}
              maxDate={moment().toISOString()} // Sets max date to current date
              onChange={handleSelectDateRange}
              datePickerProps={{
                anchorDirection: fromDrawer ? "right" : "left",
                openDirection: "down",
                appendToBody: fromDrawer,
              }}
            />
          </Box>
        )}

        {!disableAutoUpdates && (
          <Box sx={{ ...styles.formRow, ...styles.autoUpdatesRow }}>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={state.autoUpdatesOn}
                  onChange={handleAutoUpdatesCheck}
                />
              }
              sx={{
                mb: 1,
                pl: 1,
                "& .MuiFormControlLabel-label": styles.checkboxLabel,
              }}
              label="Enable auto updates"
            />
            <AutoUpdates
              hideTitle
              disabled={!state.autoUpdatesOn}
              config={state.scheduleConfig}
              setConfig={handleAutoUpdatesChange}
              error={state.error.scheduleConfig || {}}
            />
          </Box>
        )}

        {showAlert && (
          <Alert sx={{ mb: 2 }} severity="info">
            Auto update disabled: import end date is older than 1 week
          </Alert>
        )}

        {!hideDateRange && (
          <>
            <Box sx={styles.docCountSection}>
              <Typography sx={styles.docCountText} component="div">
                {state.docCountLoading ? (
                  <Box component={"span"} sx={{ mr: 1 }}>
                    <Preloader size={16} thickness={6} />
                  </Box>
                ) : (
                  <Box component={"span"} sx={{ mr: 0.5 }}>
                    {formatNumber(state.docCount)}
                  </Box>
                )}
                documents selected
              </Typography>

              <Button
                data-testid="doc-count-button"
                disabled={
                  maxCharsReached ||
                  state.docCountLoading ||
                  !state.countNeedsUpdate ||
                  !state.startDate ||
                  !state.endDate
                }
                sx={{ minHeight: "40px" }}
                variant="outlined"
                onClick={handleDocsCalculateClick}
              >
                {state.docCountLoading ? "Calculating " : "Calculate "}{" "}
                Documents
              </Button>
            </Box>

            <Box sx={{ mb: 2 }}>
              {state.noDocsError && !state.countNeedsUpdate && (
                <Typography color="error" sx={{ fontWeight: "normal" }}>
                  {state.noDocsError}
                </Typography>
              )}
            </Box>
          </>
        )}

        {showChatAlert && (
          <Alert sx={{ mb: 2 }} severity="info">
            Chat documents are threads of multiple messages. The messages are
            separated during processing, increasing the document count in
            Constellation from the estimate provided..
          </Alert>
        )}

        {state.generalError && (
          <Typography color="error" sx={{ my: 2, fontWeight: "normal" }}>
            {state.generalError}
          </Typography>
        )}

        {!fromDrawer && (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="tos-checkbox"
                  checked={state.tosChecked}
                  onChange={handleTosCheck}
                />
              }
              sx={{
                "& .MuiFormControlLabel-label": styles.checkboxLabel,
              }}
              label="I have been granted permission to upload this dataset"
            />
          </Box>
        )}
      </>
    );
  };

  return (
    <Box sx={styles.root}>
      {showMockConnectorsToggle && (
        <Box>
          <FormControlLabel
            data-testid="mockConnectorsToggle"
            control={
              <Switch
                size="small"
                checked={state.mockConnectors}
                onChange={(e) => setState({ mockConnectors: e.target.checked })}
              />
            }
            sx={{
              mb: 1,
              mt: 1,
              pl: 1,
              "& .MuiFormControlLabel-label": styles.checkboxLabel,
            }}
            label="Enable synthetic data"
          />
        </Box>
      )}

      {renderFilterControls()}

      <Box sx={{ textAlign: "center", mt: 3, ...footerStyles }}>
        <Button
          data-testid="import-data-continue-button"
          variant="contained"
          sx={{ minWidth: 125 }}
          onClick={handleContinue}
          disabled={
            loading ||
            filtersLoading ||
            state.countNeedsUpdate ||
            state.docCountLoading ||
            (!hideDateRange && state.docCount <= 0) ||
            (!fromDrawer && !state.tosChecked)
          }
          id="ContinueButton"
        >
          {loading || filtersLoading ? (
            <Preloader size={18} color="inherit" />
          ) : (
            "Continue"
          )}
        </Button>

        {fromDrawer && (
          <Button
            data-testid="import-data-cancel-button"
            onClick={onCancel}
            variant="outlined"
            color="error"
          >
            Cancel
          </Button>
        )}
      </Box>
    </Box>
  );
}
