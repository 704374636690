import axios from "axios";

import api, { analyticsApi } from "../axiosInstances/axiosAnalytics";
import { PigeonResponse } from "../response";
import { defaultReturnFields } from "../../utils/summaryReturnFields";
import { escape } from "./utils";
import { preparePlatformForApi } from "utils/platform";

const getBaseURL = () => {
  if(process.env.REACT_APP_API_BASE_URL) {
    return process.env.REACT_APP_API_BASE_URL;
  }

  // Check if its running in a branch
  if (process.env.REACT_APP_BRANCH_PATH) {
    return (
      process.env.REACT_APP_STRAPI_API.replace("/apiv4", "") +
      process.env.REACT_APP_BRANCH_PATH
    );
  }

  // Check if its running in a dev environment
  return window.location.origin.indexOf("localhost") > -1 ||
    window.location.origin.indexOf("cdn") > -1
    ? "https://staging.blackbirdailabs.com"
    : window.location.origin;
};

// Narrative Feed Status Callback URL
const NARRATIVE_FEED_URL = getBaseURL();

// TODO: rename "db" to "projectName"

// LOGIN
export const login = async (username, password) => {
  const res = await axios.post(analyticsApi + "/login", {
    identifier: username,
    password,
  });
  return escape(res.data);
};

export const checkIfSSO = async (domain) => {
  const res = await axios.post(analyticsApi + "/checkIfSSO", {
    domain,
  });
  return escape(res.data);
};

export const forgotPassword = async (email) => {
  const res = await axios.post(analyticsApi + "/forgetPassword", {
    email,
  });
  return res.data.body;
};

export const resetPassword = async (code, password, passwordConfirmation) => {
  const res = await axios.post(analyticsApi + "/resetPassword", {
    code,
    password,
    passwordConfirmation,
  });
  return escape(res.data);
};

/**
 * Authenticated APIs
 */

/**
 * Get the current user
 * @returns 
 */
export const getUser = async () => {
  try {
    const res = await api.get("/currentUser", {clearCacheEntry:true});
    return res.data.body;
  } catch {
    return null;
  }
};

/**
 * Set the user settings
 * @param {*} settings 
 * @returns 
 */
export const setUserSettings = async (settings) => {
  const res = await api.put("/user", {
    settings,
  });
  return res.data.settings;
};

/**
 * Get the filters
 * @returns 
 */
export const getFiltersAPI = async () => {
  const res = await api.get("/filters");
  return res.data.body;
};

/**
 * Get the model labels
 * @returns 
 */
export const getModelLabelsAPI = async () => {
  const res = await api.get("/modelLabels");
  return res.data.body.data;
};

/**
 * Get the field entries
 * @param {*} db 
 * @param {*} field 
 * @param {*} platform 
 * @returns 
 */
export const getFieldEntries = async ({ db, field, platform }) => {
  try {
    const response = await api.post(
      `${db}/field-entries/${field}`,
      preparePlatformForApi({ platform })
    );
    return response?.data?.body || [];
  } catch {
    return [];
  }
};

/**
 * Get the custom API
 * @param {*} db 
 * @param {*} customEndpoint 
 * @param {*} platform 
 * @returns 
 */ 
export const getCustomAPI = async ({ db, customEndpoint, platform }) => {
  try {
    const response = await api.post(
      `${db}/${customEndpoint}`,
      preparePlatformForApi({ platform })
    );
    return response?.data?.body || [];
  } catch {
    return [];
  }
};

/**
 * Get the project
 * @param {*} projectName 
 * @param {*} config 
 * @returns 
 */
export const getProject = async (projectName, config) => {
  const response = await api.get(`/projects?name=${projectName}`, config);
  return escape(response.data.body);
};

/**
 * Get the project folders
 * @returns 
 */
export const getProjectFolders = async () => {
  try {
    const response = await api.get(`/topics`);
    return {
      data: escape(response.data.body)
    };
  } catch {
    return [];
  }
};

/**
 * Add a project folder
 * @param {*} payload 
 * @returns 
 */
export const addProjectFolder = async (payload) => {
  const res = await api.post(`/topics`, { data: payload });
  return res.data;
};

/**
 * Get the user cohorts
 * @param {*} projectName 
 * @returns 
 */
export const getUserCohorts = async (projectName) => {
  try {
    const response = await api.post(`/${projectName}/userCohorts`);
    return escape(response.data.body);
  } catch {
    return [];
  }
};

/**
 * Check the data validity for a project
 * @param {*} db 
 * @param {*} fieldsToCheck 
 * @returns 
 */
export const checkDataValidityForProject = async (db, fieldsToCheck) => {
  try {
    const response = await api.post(`/field-exists/${db}`, fieldsToCheck);
    return response.data.body;
  } catch {
    return false;
  }
};

/**
 * Create a narrative
 * @param {*} data 
 * @returns 
 */
export const createNarrative = async (data) => {
  const response = await api.post("/narrative", data, {
    clearCacheEntry: true,
  });
  return response.data.body || {};
};

/**
 * Get the narratives
 * @param {*} projectName 
 * @param {*} config 
 * @returns 
 */
export const getNarratives = async (projectName, config) => {
  const response = await api.post("/narrative/list", { projectName }, config);
  return response.data?.body || [];
};

/**
 * Get the platforms
 * @param {*} projectName 
 * @param {*} config 
 * @returns 
 */
export const getPlatforms = async (projectName, config) => {
  const response = await api.post(`/${projectName}/platforms`, {}, config);
  return response.data?.body || [];
};

/**
 * Update a narrative
 * @param {*} data 
 * @returns 
 */
export const updateNarrative = async (data) => {
  const response = await api.put("/narrative", data, { clearCacheEntry: true });
  return response.data.body;
};

/**
 * Delete a narrative
 * @param {*} data 
 * @returns 
 */ 
export const deleteNarrative = async (data) => {
  const response = await api.delete("/narrative", { data });
  return response.data.body;
};

/**
 * Load concepts
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadConcepts = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/phrases/usage`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

/**
 * Load concepts related
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadConceptsRelated = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/phrases/related`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

// POSTS

/**
 * Load posts keywords
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadPostsKeywords = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/clusters`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

/**
 * Load posts
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadPosts = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/posts`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

// ENTITIES

/**
 * Load users keywords
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadUsersKeywords = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/users`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

/**
 * Load parent users keywords
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadParentUsersKeywords = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/parents`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

/**
 * Load hashtags keywords
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadHashtagsKeywords = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/hashtags`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

/**
 * Load urls
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadUrls = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/urls`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

/**
 * Load subreddits
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadSubreddits = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/subreddit`,
    preparePlatformForApi(req),
    config
  );
  return response.data.body || [];
};

/**
 * Load activity
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */ 
export const loadActivity = async ({ db, req = {}, config }) => {
  const response = await api.post(
    `/${db}/activity`,
    {
      ...preparePlatformForApi(req),
      start_date: req.start_date?.replace("Z", req.time_zone),
      end_date: req.end_date?.replace("Z", req.time_zone),
    },
    config
  );
  return response.data.body || {};
};

/**
 * Load report activity
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */ 
export const loadReportActivity = async ({ db, req = {}, config }) => {
  try {
    const response = await api.post(
      `/${db}/reportActivity`,
      {
        ...preparePlatformForApi(req),
        start_date: req.start_date?.replace("Z", req.time_zone),
        end_date: req.end_date?.replace("Z", req.time_zone),
      },
      config
    );
    return response.data.body || {};
  } catch {
    return {};
  }
};

/**
 * Fetch narrative feed
 * @param {*} projectName 
 * @returns 
 */
export const fetchNarrativeFeed = async (projectName) => {
  const response = await api.post(
    `/narrativeFeed/${projectName}/fetch`,
    {},
    { clearCacheEntry: true }
  );
  return response.data.body;
};

/**
 * Generate narrative feed
 * @param {*} projectName 
 * @returns 
 */
export const generateNarrativeFeed = async (projectName) => {
  const response = await api.post(
    `/narrativeFeed/${projectName}/generate`,
    {
      baseUrl: NARRATIVE_FEED_URL,
    },
    { clearCacheEntry: true }
  );
  return response.data.body;
};

// Call the Narrative Agent API

/**
 * Call the narrative agent
 * @param {*} projectName 
 * @param {*} prompt 
 * @returns 
 */
export const callNarrativeAgent = async (projectName, prompt) => {
  const response = await api.post(`/narrativeFeedAgent/${projectName}/agent`, {
    query: prompt
  });
  return response.data.body;
};

/**
 * Get the narrative agent response
 * @param {*} projectName 
 * @returns 
 */
export const getNarrativeAgentResponse = async (projectName) => {
  const response = await api.get(`/narrativeFeedAgent/${projectName}/agent`);
  return response.data.body;
};

/**
 * Load summary
 * @param {*} db 
 * @param {*} req 
 * @param {*} config 
 * @returns 
 */
export const loadSummary = async ({ db, req = {}, config = {} }) => {
  const response = await api.post(
    `/${db}/summary`,
    { returnFields: defaultReturnFields, ...preparePlatformForApi(req) },
    config
  );
  return response.data.body || [];
};

/**
 * Parse summary user group response
 * @param {*} resp 
 * @param {*} requests 
 * @returns 
 */
export const parseSummaryUserGroupResponse = (resp, requests) => {
  // Reduce the response to a single object
  const body = requests.reduce((obj, d, index) => {
    if (resp[index].data.body) {
    // Merge user_ct from each platform
    Object.keys(resp[index].data.body).forEach((platform) => {
      // Merge user_ct from each platform
      obj[d.user_group_names[0]] = {
        ...obj[d.user_group_names[0]],
        [platform]: {
          ...{[d.user_group_names[0]]: resp[index].data.body[platform]?.user_ct}
        },
      }
    });
  }
    return obj;
  }, {});
  return body;
}

/**
 * Load summary user groups
 * @param {*} db 
 * @param {*} requests 
 * @returns 
 */
export const loadSummaryUserGroups = async ({ db, requests }) => {
  return Promise.all(
    requests.map((req) =>
      api.post(`/${db}/summary`, { returnFields: defaultReturnFields, ...req })
    )
  ).then((resp) => {
    const body = parseSummaryUserGroupResponse(resp, requests);
    const response = new PigeonResponse(body, true);
    return {
      data: response,
    };
  });
};

// MISC

/**
 * Get zendesk token
 * @returns 
 */
export const getZendeskToken = () => {
  return api
    .get("/zendeskauth")
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      console.error(err);
    });
};

/**
 * Get organization post count by platform
 * @returns 
 */
export const getOrganizationPostCountByPlatform = async () => {
  try {
    const response = await api.get("/organization/countByPlatform");
    return response.data.body;
  } catch {
    return {};
  }
};

/**
 * Get organization post count cap
 * @returns 
 */
export const getOrganizationPostCountCap = async () => {
  try {
    const response = await api.get("/organization/post_count_cap");
    return response.data.body;
  } catch {
    return {};
  }
};

/**
 * Get model versions
 * @param {*} db 
 * @returns 
 */
export const getModelVersions = ({ db }) => {
  return api
    .post(`/${db}/modelVersions`)
    .then(({ data }) => {
      return escape(data.body);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

/**
 * Get child keys
 * @param {*} db 
 * @param {*} parent 
 * @param {*} platform 
 * @param {*} classifierProfile 
 * @returns 
 */
export const getChildKeys = ({ db, parent, platform, classifierProfile }) => {
  return api
    .post(`/${db}/child-keys/${parent}/${db}`, {
      ...preparePlatformForApi({ platform }),
      harmClassifier: classifierProfile,
    })
    .then(({ data }) => {
      return escape(data.body);
    })
    .catch((err) => {
      console.error(err);
      return null;
    });
};

/**
 * Clear project cache
 * @param {*} projectName 
 * @returns 
 */
export const clearProjectCache = async (projectName) => {
  try {
    const response = await api.get(`/clearCache/${projectName}`);
    return response.data;
  } catch {
    return null;
  }
};

/**
 * Create harm profile
 * @param {*} data 
 * @returns 
 */
export const createHarmProfile = async (data) => {
  const response = await api.post("/harmProfile", data);
  return response.data.body;
};

/**
 * Get harm profiles
 * @returns 
 */
export const getHarmProfiles = async () => {
  try {
    const response = await api.get("/harmProfile/list");
    return response.data.body || [];
  } catch {
    return [];
  }
};

/**
 * Update harm profile
 * @param {*} data 
 * @returns 
 */
export const updateHarmProfile = async (data) => {
  const response = await api.put(`/harmProfile/${data.id}`, data);
  const results = response.data.body;
  return results && typeof results === "string" ? JSON.parse(results) : results;
};

/**
 * Delete harm profile
 * @param {*} harmProfileId 
 * @returns 
 */
export const deleteHarmProfile = async (harmProfileId) => {
  const response = await api.delete(`/harmProfile/${harmProfileId}`);
  return response.data.body;
};

/**
 * Set harm profile
 * @param {*} harmProfileId 
 * @returns 
 */
export const setHarmProfile = async (harmProfileId) => {
  try {
    const response = await api.get(`/harmProfile/select/${harmProfileId}`);
    return response.data.body || {};
  } catch {
    return {};
  }
};

/**
 * Create classifier profile
 * @param {*} data 
 * @returns 
 */
export const createClassifierProfile = async (data) => {
  const response = await api.post("/classifierProfile", data);
  return response.data.body;
};

/**
 * Get classifier profiles
 * @returns 
 */
export const getClassifierProfiles = async () => {
  try {
    const response = await api.get("/classifierProfile/list");
    return response.data.body;
  } catch {
    return [];
  }
};

/**
 * Update classifier profile
 * @param {*} data 
 * @returns 
 */
export const updateClassifierProfile = async (data) => {
  const response = await api.put(`/classifierProfile/${data.id}`, data);
  return response.data.body;
};

/**
 * Set classifier profile
 * @param {*} classifierProfileId 
 * @returns 
 */
export const setClassifierProfileAPI = async (classifierProfileId) => {
  try {
    const response = await api.get(
      `/classifierProfile/select/${classifierProfileId}`
    );
    return response.data.body;
  } catch {
    return null;
  }
};

// User Group

/**
 * Create user group
 * @param {*} data 
 * @returns 
 */
export const createUserGroup = async (data) => {
  const response = await api.post("/userGroup", data);
  return response.data.body || [];
};

/**
 * Get user groups
 * @param {*} projectID 
 * @returns 
 */
export const getUserGroups = async (projectID) => {
  try {
    const response = await api.get(`/userGroup/list/${projectID}`);
    return response.data.body || [];
  } catch {
    return [];
  }
};

/**
 * Update user group
 * @param {*} data 
 * @returns 
 */
export const updateUserGroup = async (data) => {
  const response = await api.put("/userGroup", data);
  return response.data.body;
};

/**
 * Delete user group
 * @param {*} userGroupId 
 * @returns 
 */
export const deleteUserGroup = async (userGroupId) => {
  const response = await api.delete("/userGroup", {
    data: { id: userGroupId },
  });
  return response.data.body;
};

/**
 * Get AI narratives
 * @param {*} db 
 * @param {*} req 
 * @returns 
 */
export const getAINarratives = async ({ db, req }) => {
  try {
    const response = await api.post(
      `/${db}/ai-narratives`,
      preparePlatformForApi(req)
    );
    return response.data.body;
  } catch {
    return [];
  }
};

/**
 * Load domains
 * @param {*} db 
 * @param {*} req 
 * @returns 
 */
export const loadDomains = async ({ db, req }) => {
  try {
    const response = await api.post(
      `/${db}/domains`,
      preparePlatformForApi(req)
    );
    return response.data.body || [];
  } catch {
    return [];
  }
};
