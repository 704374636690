import axios from "axios";

import { handleError } from "api/errors/errors";
import { addRefreshTokenInterceptor } from "../interceptors/refreshTokenInterceptor";
import { JWT_TOKEN_FIELD } from "utils/constants";

export const analyticsApi =
  process.env.REACT_APP_ANALYTIC_API || "/analyticapi";

export const buildApiInstance = () => {
  // Create `axios` instance passing the newly created `cache.adapter`
  const api = axios.create({
    baseURL: analyticsApi,
    timeout: 600000,
  });

  api.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem(JWT_TOKEN_FIELD);
      const auth = token ? `Bearer ${token}` : "";
      config.headers["Authorization"] = auth;
      return config;
    },
    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      // show error toaster
      handleError(error);
      return Promise.reject(error);
    }
  );

  return api;
};

const api = buildApiInstance();
addRefreshTokenInterceptor(api);

export default api;
