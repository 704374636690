import { alpha, styled, Switch } from "@mui/material";
import COLORS from "colors";

/**
 * CustomSwitch is a styled component that changes the color of the switch when it is checked.
 * It uses the COLORS object to get the color of the indigo.main color.
 * 
 * @param theme - The theme object.
 * @returns The styled component.
 */
export const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: COLORS.indigo.main,
      '&:hover': {
        backgroundColor: alpha(COLORS.indigo.main, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: COLORS.indigo.main,
    },
}));