import TalkwalkerLogoUrl from "icons/Uploader/talkwalker-logo.png";
import MeltwaterLogoUrl from "icons/Uploader/meltwater-logo.svg";
import TwitterLogoUrl from "icons/Uploader/twitter-api-logo.svg";
import BrandwatchLogoUrl from "icons/Uploader/brandwatch-logo.svg";
import WebzLogoUrl from "icons/Uploader/webz-logo.svg";
import S3LogoUrl from "icons/Uploader/S3-logo.svg";
import { ReactComponent as FileuploadIcon } from "icons/Uploader/fileupload-icon.svg";
import { ReactComponent as MeltwaterIcon } from "icons/Uploader/meltwater-icon.svg";
import { ReactComponent as TwitterIcon } from "icons/Uploader/twitter-api-icon.svg";
import { ReactComponent as BrandwatchIcon } from "icons/Uploader/brandwatch-icon.svg";
import { ReactComponent as WebzIcon } from "icons/Uploader/webz-icon.svg";
import { ReactComponent as S3Icon } from "icons/Uploader/S3-icon.svg";
import FileIcon from "icons/Uploader/File.svg";
import { getISOString, offsetDate } from "utils/format";
import { SUPPORT_EMAIL } from "utils/constants";

export const CONNECTORS = {
  talkwalker: "talkwalker",
  meltwater: "meltwater",
  fileupload: "fileupload",
  twitter: "twitter",
  externalMeltwater: "external-meltwater",
  externalBrandwatch: "external-brandwatch",
  brandwatch: "brandwatch",
  webz: "webz",
  s3: "s3",
};

export const CONNECTOR_FORM_TYPES = {
  select: "select",
  textInput: "textInput",
  boolInput: "boolInput",
  dateRange: "date-range",
  autoUpdates: "auto-updates",
};

const newApiKey = [
  {
    id: "api_key",
    displayName: "Enter a new API key",
    field: "api_key",
    type: CONNECTOR_FORM_TYPES.textInput,
    actionName: "Enter a new API key",
    placeholder: "Paste API key here",
    required: true,
    source: {},
  },
  {
    id: "name",
    displayName: "Name your API key",
    field: "name",
    type: CONNECTOR_FORM_TYPES.textInput,
    actionName: "Name your API key",
    placeholder: "e.g. Client A",
    required: true,
    source: {},
  },
];

const fileUploadConfig = {
  id: CONNECTORS.fileupload,
  key: CONNECTORS.fileupload,
  displayName: "File Upload",
  actionLabel: "File Upload",
  logo: FileIcon,
  icon: FileuploadIcon,
  shouldLoadFilters: false,
  connectionConfig: {},
  connectionFields: [],
  supportsAutoUpdate: false,
  fields: [],
  order: 0,
};

const talkWalkerConfig = {
  id: CONNECTORS.talkwalker,
  key: CONNECTORS.talkwalker,
  dataSourceTitle: "Connect to Talkwalker",
  dataSourceSubtitle: "",
  displayName: "Talkwalker",
  actionLabel: "Import from Talkwalker",
  logo: TalkwalkerLogoUrl,
  icon: () => <img src={TalkwalkerLogoUrl} style={{ width: "16px", height: "16px" }} alt="Talkwalker" />,
  shouldLoadFilters: false,
  connectionConfig: {
    connectionMode: "named-api-key",
    confirmUserAuthorization: true,
  },
  order: 1,
  noDocsError:
    "We can't find any documents. Try updating your import configuration.",
  supportsAutoUpdate: true,
  connectionFields: newApiKey,
  createNewConnectionLabel: "Enter a new API key",
  chooseConnectionLabel: "Choose API key",
  fields: [
    {
      id: "projects",
      field: "dataProviderProjectId",
      // this is used to show project name in the data receipt
      infoField: "constellationProjectName",
      type: CONNECTOR_FORM_TYPES.select,
      mode: "single-select",
      actionName: "Choose Talkwalker project",
      placeholder: "- Select Talkwalker project -",
      displayName: "Project",
      required: true,
      source: {
        type: "bb-filters",
        propName: "projects",
        key: "id",
        displayName: "name",
      },
      fields: [
        {
          id: "topics",
          field: "dataProviderTopicId",
          infoField: "dataProviderTopicName",
          type: CONNECTOR_FORM_TYPES.select,
          mode: "single-select",
          actionName: "Choose Talkwalker Topic",
          placeholder: "- Select Talkwalker Topic -",
          displayName: "Topics",
          required: true,
          source: {
            type: "bb-filters",
            propName: "topics",
            key: "id",
            displayName: "name",
          },
          fields: [],
        },
      ],
    },
    {
      id: "platforms",
      field: "selectedPlatform",
      infoField: "selectedPlatform",
      type: "select",
      mode: "multi-select",
      actionName: "Select social platforms",
      placeholder: "- Select social platforms -",
      displayName: "Social platforms",
      required: true,
      source: {
        type: "static",
        items: [
          { id: "Twitter", name: "Twitter" },
          { id: "Reddit", name: "Reddit", isBeta: true },
          { id: "news", name: "News", isBeta: true },
        ],
        key: "id",
        displayName: "name",
      },
      fields: [],
    },
  ],
};

const meltwaterConfig = {
  id: CONNECTORS.meltwater,
  key: CONNECTORS.meltwater,
  shouldLoadFilters: false,
  dataSourceTitle: "Connect to Meltwater",
  dataSourceSubtitle: "",
  displayName: "Meltwater",
  actionLabel: "Import from Meltwater",
  noDocsError:
    "We can't find any documents. Try updating your import configuration.",
  logo: MeltwaterLogoUrl,
  icon: MeltwaterIcon,
  order: 2,
  supportsAutoUpdate: true,
  connectionFields: newApiKey,
  createNewConnectionLabel: "Enter a new API key",
  chooseConnectionLabel: "Choose API key",
  fields: [
    {
      id: "platforms",
      field: "selectedPlatform",
      infoField: "selectedPlatform",
      type: "select",
      mode: "multi-select",
      actionName: "Choose social platforms",
      placeholder: "- Select social platforms -",
      displayName: "Social platforms",
      required: true,
      source: {
        type: "static",
        items: [
          { id: "twitter", name: "Twitter", disabled: true },
          { id: "reddit", name: "Reddit", disabled: true, isBeta: true },
          { id: "news", name: "News", disabled: true, isBeta: true },
        ],

        key: "id",
        displayName: "name",
      },
      fields: [],
      defaultValue: ["twitter", "reddit", "news"],
      infoAlert:
        "Meltwater doesn't allow for platform filtering via API, thus all data from your Search will be imported and only News, Reddit, and Twitter data will appear in Constellation. For more efficient project creation and to minimize Meltwater credit usage, revise your Search in Meltwater to only include these supported platforms.",
    },
    {
      id: "topics",
      field: "dataProviderTopicId",
      infoField: "dataProviderTopicName",
      type: CONNECTOR_FORM_TYPES.select,
      mode: "single-select",
      actionName: "Choose Meltwater search",
      placeholder: "- Select Meltwater search -",
      displayName: "Search",
      required: true,
      source: {
        type: "bb-filters",
        propName: "topics",
        key: "id",
        displayName: "name",
      },
      fields: [],
    },
  ],
};

const twitterConfig = {
  id: CONNECTORS.twitter,
  key: CONNECTORS.twitter,
  dataSourceTitle: "Connect to Twitter",
  dataSourceSubtitle: "",
  displayName: "Twitter",
  actionLabel: "Import from Twitter",
  logo: TwitterLogoUrl,
  icon: TwitterIcon,
  order: 3,
  shouldLoadFilters: false,
  noDocsError:
    "We can't find any documents. Try updating your search query or date range, then calculate documents again.",
  supportsAutoUpdate: false,
  connectionFields: newApiKey,
  createNewConnectionLabel: "Enter a new API key",
  chooseConnectionLabel: "Choose API key",
  fields: [
    {
      id: "SearchQuery",
      type: CONNECTOR_FORM_TYPES.boolInput,
      maxChars: 1024,
      field: "searchQuery",
      infoField: "searchQuery",
      actionName: "Define search query",
      displayName: "Search query",
      learnMore: {
        text: "Learn more about Twitter search queries",
        link: "https://developer.twitter.com/en/docs/twitter-api/tweets/search/integrate/build-a-query",
      },
      source: {
        type: "static",
        items: [],
        key: "",
        displayName: "",
      },
    },
  ],
};

const externalMeltwaterConfig = {
  // Unique identifier of the data connector.
  id: CONNECTORS.externalMeltwater,
  key: CONNECTORS.externalMeltwater,
  dataSourceTitle: "Connect to Meltwater",
  dataSourceSubtitle: "",
  displayName: "Meltwater",
  actionLabel: "Import from Meltwater",
  icon: MeltwaterIcon,
  logo: MeltwaterLogoUrl,
  shouldLoadFilters: true, // if true, it will call /filters endpoint
  supportsAutoUpdate: true,
  connectionConfig: {
    // The structure of the connection can be set here. Other possible option: "csv-file"
    connectionMode: "named-api-key",
    // Indicated whether to show the checkbox to confirm user's permission.
    confirmUserAuthorization: true,
  },
  connectionFields: newApiKey,
  createNewConnectionLabel: "Enter a new API key",
  chooseConnectionLabel: "Choose API key",
  // The collection of fields displayed for query configuration step.
  fields: [
    {
      id: "topic_id",
      field: "dataProviderTopicId",
      infoField: "dataProviderTopicName",
      type: "select",
      mode: "single-select",
      actionName: "Choose Meltwater topic",
      placeholder: "- Select Meltwater topic -",
      displayName: "Topic",
      required: false,
      source: {
        type: "parent-filter",
        propName: "topics",
        key: "id",
        displayName: "name",
      },
    },
    {
      id: "platforms", // Unique identifier
      field: "selectedPlatform", // Property name used when sending the task creation request to the backend.
      infoField: "selectedPlatform",
      type: "select", // Field type. "select" is a drop down field.
      mode: "multi-select",
      actionName: "Choose social platforms", // The diplay name used when showing the selection control.
      placeholder: "- Select social platforms -",
      displayName: "Social platforms", // The display name used when showing user selection.
      required: true,
      source: {
        type: "static",
        items: [
          { id: "twitter", name: "Twitter", disabled: true },
          { id: "reddit", name: "Reddit", disabled: true, isBeta: true },
          { id: "news", name: "News", disabled: true, isBeta: true },
        ],
        key: "id",
        displayName: "name",
      },
      fields: [], // Child fields that depend on the selection of the current field.
      defaultValue: ["twitter", "reddit", "news"],
      infoAlert:
        "Meltwater doesn't allow for platform filtering via API, thus all data from your Search will be imported and only News, Reddit, and Twitter data will appear in Constellation. For more efficient project creation and to minimize Meltwater credit usage, revise your Search in Meltwater to only include these supported platforms.",
    },
  ],
  order: 5,
};

const externalBrandwatchConfig = {
  // Unique identifier of the data connector.
  id: CONNECTORS.externalBrandwatch,
  key: CONNECTORS.externalBrandwatch,
  dataSourceTitle: "Connect to Brandwatch",
  dataSourceSubtitle: "",
  displayName: "Brandwatch",
  actionLabel: "Import from Brandwatch",
  icon: BrandwatchIcon,
  logo: BrandwatchLogoUrl,
  supportsAutoUpdate: true,
  shouldLoadFilters: true,
  connectionConfig: {
    // The structure of the connection can be set here. Other possible option: "csv-file"
    connectionMode: "named-api-key",
    // Indicated whether to show the checkbox to confirm user's permission.
    confirmUserAuthorization: true,
  },
  connectionFields: newApiKey,
  createNewConnectionLabel: "Enter a new API key",
  chooseConnectionLabel: "Choose API key",
  // The collection of fields displayed for query configuration step.
  fields: [
    {
      id: "project_id", // Unique identifier
      field: "dataProviderProjectId", // Property name used when sending the task creation request to the backend.
      infoField: "constellationProjectName",
      type: "select", // Field type. "select" is a drop down field.
      mode: "single-select", // The style of rendering the dropdown. It also indicates the value assigned to "field" property is a constant.
      actionName: "Choose Brandwatch project", // The display name used when showing the selection control.
      placeholder: "- Select Brandwatch project -", // The display name used when showing the selection control.
      displayName: "Project", // The display name used when showing user selection.
      required: true,
      source: {
        type: "easl-filters", // When EASL sources is set, the values are retrieved from the result of "EASL Filters" API call response.
        propName: "projects", // The property that contains the array results for the field within "EASL Filters" response.
        key: "id", // The key property to use from "EASL Filters" response object.
        displayName: "name", // The display name property to use from "EASL Filters" response object.
      },
      fields: [
        {
          id: "topic_id",
          field: "dataProviderTopicId",
          infoField: "dataProviderTopicName",
          type: "select",
          mode: "single-select",
          actionName: "Choose Brandwatch query",
          placeholder: "- Select Brandwatch query",
          displayName: "Query",
          required: false,
          source: {
            type: "parent-filter",
            propName: "topics",
            key: "id",
            displayName: "name",
          },
        },
      ], // Child fields that depend on the selection of the current field.
    },
    {
      id: "platforms", // Unique identifier
      field: "selectedPlatform", // Property name used when sending the task creation request to the backend.
      infoField: "selectedPlatform",
      type: "select", // Field type. "select" is a drop down field.
      mode: "multi-select",
      actionName: "Choose social platforms", // The diplay name used when showing the selection control.
      placeholder: "- Select social platforms -", // The diplay name used when showing the selection control.
      displayName: "Social platforms", // The display name used when showing user selection.
      required: true,
      source: {
        type: "static",
        items: [
          { id: "twitter", name: "Twitter" },
          { id: "reddit", name: "Reddit", isBeta: true },
          { id: "news", name: "News", isBeta: true },
          { id: "chat", name: "Chat", isBeta: true }, // This is for telegram
        ],
        key: "id",
        displayName: "name",
      },
      fields: [], // Child fields that depend on the selection of the current field.
    },
  ],
  order: 5,
};

const brandwatchConfig = {
  ...externalBrandwatchConfig,
  id: CONNECTORS.brandwatch,
  key: CONNECTORS.brandwatch,
  shouldLoadFilters: false,
  fields: [
    {
      ...externalBrandwatchConfig.fields[0],
      source: {
        ...externalBrandwatchConfig.fields[0].source,
        type: "bb-filters",
      },
      fields: [
        {
          ...externalBrandwatchConfig.fields[0].fields[0],
          source: {
            ...externalBrandwatchConfig.fields[0].fields[0].source,
            type: "bb-filters",
          },
        },
      ],
    },
    externalBrandwatchConfig.fields[1],
  ],
};

const webzConfig = {
  id: CONNECTORS.webz,
  key: CONNECTORS.webz,
  dataSourceTitle: "Connect to Webz",
  dataSourceSubtitle: "",
  displayName: "Webz",
  actionLabel: "Import from Webz",
  logo: WebzLogoUrl,
  icon: WebzIcon,
  order: 6,
  shouldLoadFilters: true,
  noDocsError:
    "We can't find any documents. Try updating your search query or date range, then calculate documents again.",
  supportsAutoUpdate: true,
  connectionFields: newApiKey,
  createNewConnectionLabel: "Enter a new API key",
  chooseConnectionLabel: "Choose API key",
  fields: [
    {
      id: "platforms",
      field: "selectedPlatform",
      infoField: "selectedPlatform",
      type: "select",
      mode: "multi-select",
      actionName: "Select platforms",
      placeholder: "- Select -",
      displayName: "Platforms",
      required: true,
      source: {
        propName: "platforms",
        key: "id",
        displayName: "displayName",
        areAllBeta: true,
      },
      fields: [], // Child fields that depend on the selection of the current field.
    },
    {
      id: "query",
      type: CONNECTOR_FORM_TYPES.boolInput,
      maxChars: 3500,
      field: "searchQuery",
      infoField: "searchQuery",
      actionName: "Define search query",
      displayName: "Search query",
      learnMore: {
        text: "Learn more about Webz search queries",
        link: "https://docs.webz.io/reference/faqs-2",
      },
      source: {
        type: "static",
        items: [],
        key: "",
        displayName: "",
      },
    },
  ],
};

const amazonS3ConnectionFields = [
  {
    id: "accessKeyId",
    displayName: "AWS Access Key ID",
    field: "accessKeyId",
    type: CONNECTOR_FORM_TYPES.textInput,
    actionName: "AWS Access Key ID",
    placeholder: "Enter Access Key ID here",
    tooltip:
      "You can generate an Access Key within “My Security Credentials” of AWS “Identity and Access Management.”",
    required: true,
    source: {},
  },
  {
    id: "api_key",
    displayName: "AWS Secret Access Key",
    field: "api_key",
    type: CONNECTOR_FORM_TYPES.textInput,
    isSecret: true,
    actionName: "AWS Secret Access Key",
    placeholder: "Enter Secret Access Key here",
    tooltip:
      "You can find your secret access key with your Access Key ID in AWS “Identity and Access Management”",
    required: true,
    source: {},
  },
  {
    id: "bucketName",
    displayName: "Bucket name",
    field: "bucketName",
    type: CONNECTOR_FORM_TYPES.textInput,
    actionName: "Bucket name",
    placeholder: "Enter Bucket name here",
    tooltip:
      "The location in S3 where your files are stored, formatted as 's3://bucket-name/'",
    required: true,
    source: {},
  },
  {
    id: "awsRegion",
    displayName: "AWS Region",
    field: "awsRegion",
    type: CONNECTOR_FORM_TYPES.select,
    mode: "single",
    actionName: "AWS Region",
    placeholder: "- Select -",
    tooltip:
      "The geographic area for your AWS resources, affecting latency and availability",
    required: true,
    source: {
      type: "static",
      items: [
        { id: "us-east-1", name: "US East (N. Virginia)" },
        { id: "us-east-2", name: "US East (Ohio)" },
        { id: "us-west-1", name: "US West (N. California)" },
        { id: "us-west-2", name: "US West (Oregon)" },
        { id: "ca-central-1", name: "Canada (Central)" },
      ],
      key: "id",
      displayName: "name",
    },
  },
  {
    id: "name",
    displayName: "Name your connection",
    field: "name",
    type: CONNECTOR_FORM_TYPES.textInput,
    actionName: "Name your connection",
    placeholder: "“S3 production”",
    required: true,
    source: {},
  },
];

const s3Config = {
  id: CONNECTORS.s3,
  key: CONNECTORS.s3,
  displayName: "S3",
  contextMenuActionLabel: "Configure S3 imports",
  contextMenuExportLabel: "Configure S3 exports",
  actionLabel: "Import from Amazon S3",
  dataSourceTitle: "Amazon S3 Source Configuration",
  dataExportTitle: "Amazon S3 Destination Configuration",
  dataSourceSubtitle:
    "This is where you will upload your unprocessed CSV files",
  dataExportSubtitle: "",

  dataSourceInfo: [
    "For detailed documentation on Amazon S3 integration, visit our <a target='_blank' href='https://blackbirdai.zendesk.com/hc/en-us/articles/23611314973585-Project-Creation-Amazon-S3'>Help Center</a>.",
    `If this is your organization’s first time utilizing S3 data connections, please reach out to <a href='mailto:${SUPPORT_EMAIL}'> ${SUPPORT_EMAIL} </a> to initiate technical setup.`,
  ],
  logo: S3LogoUrl,
  icon: S3Icon,
  order: 7,
  shouldLoadFilters: false,
  noDocsError: "",
  supportsAutoUpdate: false,
  connectionFields: amazonS3ConnectionFields,
  createNewConnectionLabel: "Add new connection",
  chooseConnectionLabel: "Choose S3 connection",
  fields: [],
};

const DATA_CONNECTORS = [
  fileUploadConfig,
  talkWalkerConfig,
  meltwaterConfig,
  twitterConfig,
  externalMeltwaterConfig,
  externalBrandwatchConfig,
  brandwatchConfig,
  webzConfig,
  s3Config,
];

export const DATA_CONNECTORS_LOOKUP = new Map(
  DATA_CONNECTORS.map((d) => [d.id, d])
);

const Data_Connector_Flags = {
  [CONNECTORS.twitter]: "twitterApi",
  [CONNECTORS.meltwater]: "meltwater",
  [CONNECTORS.brandwatch]: "brandwatch",
  [CONNECTORS.externalBrandwatch]: "brandwatchEasl",
  [CONNECTORS.externalMeltwater]: "meltwaterEasl",
  [CONNECTORS.webz]: "webzConnector",
  [CONNECTORS.s3]: "s3Connector",
};

export const getConnectors = (flags) => {
  return DATA_CONNECTORS.filter((d) => {
    const flag = Data_Connector_Flags[d.id];
    return flag ? flags[flag] : true;
  }).sort((a, b) => a.order - b.order);
};

export const getRequestFilters = (
  connectorConfig,
  dataImportConfig,
  timezoneOffset
) => {
  const defaultDate = getISOString(new Date());

  const allFields = connectorConfig.fields.flatMap((d) => [
    d,
    ...(d.fields || []),
  ]);

  const obj = {
    start_date: offsetDate(
      dataImportConfig.startDate || defaultDate,
      timezoneOffset
    ),
    end_date: offsetDate(
      dataImportConfig.endDate || defaultDate,
      timezoneOffset
    ),
  };

  allFields.forEach((d) => {
    obj[d.id] = dataImportConfig[d.field];
  });

  return obj;
};

/**
 * Parses the given input object into a Strapi Talkwalker CMS object.
 *
 * @param {object} job - The input object to parse.
 * @return {object} The parsed object with selected properties from the input.
 */
export const getDataConnectorJobPayload = (job) => {
  const dataConnectorType = job["dataProviderName"].toLowerCase();
  const getFilters = () => {
    if (!dataConnectorType.startsWith("external-")) return undefined;

    return {
      start_date: job.startDate,
      end_date: job.endDate,
      project_id: job.dataProviderProjectId,
      topic_id: job.dataProviderTopicId,
      platforms: job.selectedPlatform,
    };
  };

  const filters = getFilters();
  const transformedPayload = {
    ...job,
    // The strapi data connector job data model expects startDateTime and endDateTime.
    startDateTime: job.startDate,
    endDateTime: job.endDate,
    filters: filters,
  };

  return transformedPayload;
};
