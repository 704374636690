import mixpanel from "mixpanel-browser";

export enum EventTypes {
    click = "click",
    select = "select",
    search = "search",
    add = "add",
    remove = "remove",
}

export interface ICustomEvent {
    type: EventTypes | string,
    value: string | object,
    path: string,
    timestamp: number,
}

interface UserEventParams {
    type?: EventTypes | string,
    value?: string | object,
    path?: string,
    project: string,
    platform: string,
    narrative: string,
}

class CustomEvent implements ICustomEvent {
    type: EventTypes | string;
    value: string | object;
    path: string;
    project: string;
    platform: string;
    narrative: string;
    timestamp: number;

    constructor(params: UserEventParams) {
        this.type = params.type;
        this.value = params.value || "";
        this.path = params.path || "";
        this.project = params.project;
        this.platform = params.platform;
        this.narrative = params.narrative;
    }

    toObject() {
        let obj: any = {};

        if (typeof this.value === "object") {
            obj = this.value;
        } else {
            obj.value = this.value;
        }

        return {
            type: this.type,
            path: this.path,
            project: this.project,
            platform: this.platform,
            narrative: this.narrative,
            timestamp: Date.now(),
            ...obj,
        }
    }
}

export const trackUserEvent = (message: string, params: UserEventParams): void => {
    let event = undefined;

    if (params.type) {
        event = new CustomEvent(params)
    }
    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        mixpanel.track(message, event.toObject());
    }
}