import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import globalMuiTheme from "./globalMuiTheme";
import { ContextProvider } from "./context/Context";
import * as serviceWorker from "./serviceWorker";
import { hotjar } from "react-hotjar";

import { BrowserRouter as Router } from "react-router-dom";

import mixpanel from "mixpanel-browser";
import { QueryClient, QueryCache, QueryClientProvider } from "@tanstack/react-query";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { Userpilot } from "userpilot";
import { datadogRum } from '@datadog/browser-rum';

import "styles/fontello.css";
import "react-dates/initialize";
import "styles/react-dates-custom.css";
import axios from "axios";

if (process.env.REACT_APP_HOTJAR_ID) {
  hotjar.initialize(
    process.env.REACT_APP_HOTJAR_ID, // hjid
    6 // hjsv
  );
}

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
}

Userpilot.initialize("NX-3e17e157");

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
  queryCache: new QueryCache({
    onError: (error) => {
      if (axios.isCancel(error)) return;
      console.error("Something went wrong", error);
    },
  }),
});

datadogRum.init({
  applicationId: 'ed335d44-06ad-4ac7-8b8e-d44375f47c00',
  clientToken: 'pub310b2746ea4404e09dacf5ba5bad10b6',
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  site: 'datadoghq.com',
  service: 'constellation',
  env: process.env.REACT_APP_ENV,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  defaultPrivacyLevel: 'mask-user-input',
});

asyncWithLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCHDARKLY_ID,
}).then((LDProvider) => {
  const container = document.getElementById("root");
  if (!container) {
    return null;
  }
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={globalMuiTheme}>
          <CssBaseline />
          <ContextProvider>
            <Router basename={process.env.REACT_APP_BRANCH_PATH}>
              <QueryClientProvider client={queryClient}>
                <LDProvider>
                  <App />
                </LDProvider>
              </QueryClientProvider>
            </Router>
          </ContextProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </React.StrictMode>
  );
});
serviceWorker.unregister();
